import axios from 'axios';

const api = axios.create({
  baseURL: process.env.VUE_APP_BASE_EYE_TRACKING_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export default class EyeTrackingApi {
  static async sendEyeTrackingData(code: string, quesUuid: string, tracking: string, isDone: boolean, sourceUuid: string): Promise<void> {
    await api.post('/tracking/data', {
      shortId: code,
      quesUuid,
      tracking,
      isDone,
      sourceUuid,
    }).catch((error) => {
      console.log(error);
    });
  }

  static async sendQuizData(quizUuid: string, shortId: string, answerList: string, sourceQuizGroupName: string, sourceQuizGroupId: string): Promise<void> {
    await api.post('/quiz/group', {
      uuid: quizUuid, shortId, answerList, sourceQuizGroupName, sourceQuizGroupId,
    }).catch((error) => {
      console.log(error);
    });
  }
}
