

























































































import Vue from 'vue';
import Cycle from '../components/calibration/Cycle.vue';

export default Vue.extend({
  components: {
    Cycle,
  },
  data() {
    return {
      code: '',
      currentStep: 0,
      clickCount: 5,
      xprediction: 0,
      yprediction: 0,
      stepList: [
        {
          stepStyle: 'margin-top: 2%; margin-left: 2%',
        },
        {
          stepStyle: 'margin-top: 2%; float: right; margin-right: 2%',
        },
        {
          stepStyle: 'margin-top: 62%; float: left; margin-left: 20%',
        },
        {
          stepStyle: 'margin-top: 62%; float: right; margin-right: 20%',
        },
        {
          stepStyle: 'margin-top: 100%; float: left; margin-left: 20%',
        },
        {
          stepStyle: 'margin-top: 100%; float: right; margin-right: 20%',
        },
      ],
    };
  },
  //   mounted() {
  //   },
  created() {
    this.code = this.$route.query.code as string;
    this.startTrack();
  },
  methods: {
    quitCalibration() {
      try {
        window.webgazer.end();
      } catch (error) {
        console.log(error);
      }

      if (this.code === '' || this.code === undefined || this.code === null) {
        console.log('empty');
        this.$router.push({
          path: '/',
        });
      } else {
        console.log('not empty');

        this.$router.push({
          path: '/ing',
          query: {
            code: `${this.code}`,
            allowEyeTracking: 'TRUE',
          },
        });
      }
    },
    restartCalibration() {
      this.currentStep = 0;
    },
    cycleClicked() {
      this.clickCount -= 1;
      if (this.clickCount === 0) {
        this.clickCount = 5;
        this.currentStep += 1;
        if (this.currentStep === 7) {
          window.webgazer.end();
        }
      }
    },
    stepPlus() {
      this.currentStep += 1;
      if (this.currentStep === 7) {
        window.webgazer.end();
      }
    },
    startCalib() {
      this.currentStep = 1;
    },
    clicked() {
      console.log('clicked');
    },
    startTrack() {
      window.webgazer.setGazeListener((data: { x: any; y: any; }|null, elapsedTime: any) => {
        if (data == null) {
          return;
        }
        this.xprediction = data.x; // these x coordinates are relative to the viewport
        this.yprediction = data.y; // these y coordinates are relative to the viewport
        // console.log(`elapsedTime: ${elapsedTime}`); // elapsed time is based on time since begin was called
      }).begin();
      window.webgazer.showFaceOverlay(false);
      window.webgazer.showVideo(false);
      window.webgazer.setVideoViewerSize(1, 1);
      // eslint-disable-next-line prefer-const
      // let child = document.getElementById('webgazerVideoFeed') as any;
      // child.parentNode.removeChild(child);
    },
    doubleClick() { // For handling double click event
      console.log('double');
    },
  },
});
